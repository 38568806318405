.aboutMe{
    display: flex;
    flex-direction: column;
}

.about-container{
    padding: 5rem 0;
    width: 90%;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.about-title{
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: 600;
}

.about-parrafo{
    max-width: 60ch;
    font-size: 1.5rem;
    font-family: inter, sans-serif;
    box-sizing: border-box;
   
}