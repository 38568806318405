.contacto-container {
  padding-top: 5rem ;
  padding-bottom: 5rem;
}

.contato-texto{
margin-bottom: 2rem;
}

.inter {
  font-family: inter;
}

.titulo-contacto{
  margin-bottom: 2rem;
  /* font-size: 2.5rem; */
  font-weight: 600;
}

.contacto-parrafo{
  max-width: 60ch;
  /* font-size: 1.5rem; */
  font-family: inter, sans-serif;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
 
}

.input-styles {
  /* border-bottom: 2px solid red !important;  */
  border: none;

  border-image-slice: 1;
  width: 100%;
  color: rgba(0, 0, 0, 0);
  border-radius: none !important;
  background-size: 0 3px, 100% 3px, 100% 3px !important;
  background-position: 100% 100%, 0 100%, 0 100% !important;
  background-repeat: no-repeat !important;
  outline: none;
  transition: 1s;
}

.input-styles:focus {
  background-size: 0 3px, 0 3px, 100% 3px !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.botton-submit {
  border: 2px solid;
  transition: 0.3s;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper-wrong {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin: 10% auto;
  color: white;
  -webkit-animation: fillwrong 0.4s ease-in-out 0.4s forwards,
  scale 0.3s ease-in-out 0.9s both;
  -moz-animation: fillwrong 0.4s ease-in-out 0.4s forwards,
  scale 0.3s ease-in-out 0.9s both;  
  animation: fillwrong 0.4s ease-in-out 0.4s forwards,
  scale 0.3s ease-in-out 0.9s both;  
}

.cog{
    width: 26px;
    -webkit-animation: rotate 5s normal linear infinite;
    animation: rotate 5s normal linear infinite;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  -moz-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
    -moz-animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 100% 100%;
  stroke-dasharray: 72;
  stroke-dashoffset: 48;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  -moz-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

@keyframes fillwrong {
  100% {
    box-shadow: inset 0px 0px 0px 30px red;
  }
}

@keyframes rotate {
    0% {
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
      transform: rotate(0, 0, 1, 0deg);
    }
    50% {
        -webkit-transform: rotate3d(0, 0, 1, 180deg);
        transform: rotate3d(0, 0, 1, 180deg);
      }
      75% {
        -webkit-transform: rotate3d(0, 0, 1, 270deg);
        transform: rotate3d(0, 0, 1, 270deg);
      }
      100% {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
      }
}


@media screen and (min-width: 992px) {
  .botton-submit:hover {
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
    font-weight: 600;
  }
  
}