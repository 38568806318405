.proyectos {
  width: 100%;
  display: flex;
  justify-content: center;
}

.projects-container {
  width: 90%;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
}

.proyects-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
}

.own-projects{
    min-height: 300px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.learning-container {
  gap: 50px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.image-container {
  display: flex;
  width: 50px;
  justify-content: center;
}

.institution-container {
  display: flex;
  align-items: center;
}

.udemy-imagen {
  height: 150px;
  width: 40px;
}



.oracle-imagen {
  height: 150px;
  width: 40px;
  /* width: 65px; */
}

.learning-projects {
  width: -moz-calc(100% - 100px);
  width: -webkit-calc(100% - 100px);
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
}

.project-card {
  aspect-ratio: 16 / 9;
  height: 200px;
  margin: 20px 10px;
  background-size: cover;
  border-radius: 15px;
  background-color: black;
  display: flex;
  justify-content: center;
  
}







.blur-card {
    visibility: hidden;

  /* display: none; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.07);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  -moz-backdrop-filter: blur(2px);
  transition: 0.2s;
}

.project-card:hover > .blur-card {
    visibility: visible;
    -webkit-animation: blur-animation 0.5s;
    -moz-animation: blur-animation 0.5s;
    animation: blur-animation 0.6s; 
    
    
  }


  

.proyect-anchors{
margin-top: 20%;
opacity: 0;
transition: 1s;

}



.project-card:hover  .proyect-anchors {
    opacity: 100%;
    
    
    
  }



.proyect-link{
    color: black;
    margin: 15px;
    
    
    
}

.bi-link-45deg{
    height: 32px;
    width: 32px;
    
    border-radius: 50%;
    
    
}

.bi-github{
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.proyect-description{
    opacity: 0;
    margin-top: 10%;
    justify-self: end;
    font-size: 0.1rem;
    text-shadow:
    0.02em 0 black,
    0 0.02em black,
    -0.02em 0 black,
    0 -0.02em black,
    -0.02em -0.02em black,
    -0.02em 0.02em black,
    0.02em -0.02em black,
    0.02em 0.02em black;
    transition: 0.6s;
}
.project-card:hover  .proyect-description {
    opacity: 100%;
    font-size: 1.1rem;
    
    
    
  }

@keyframes blur-animation {
    0%{
        width: 0%;
        height: 0%;
    }
    60%{
        width: 105%;
        height: 105%;
    }
    80%{
        width: 90%;
        height: 90%;
    }
    100%{
        width: 100%;
        height: 100%;
    }

}

@keyframes anchor-animation {
    0%{
        width: 0%;
        height: 0%;
    }
    50%{
        width: 0%;
        height: 0%;
    }
    100%{
        width: 100%;
        height: 100%;
    }

}

@media only screen and (min-width: 992px){
  .proyect-link:hover{
    /* color: rgba(6, 3, 173, 0.5) !important;    */
    opacity: 75%; 
}
  
}





@media only screen and (max-width: 770px) {
    .institution-container {
        flex-direction: column;
    }

    .image-container {
        display: flex;
        width: 100%;
        height: 50px;
        justify-content: center;
        flex-wrap: wrap;
      }

    .oracle-imagen, .udemy-imagen {
        /* -webkit-rotate: 90deg;
        -o-rotate: 90deg;
        -moz-rotate: 90deg;
        rotate: 90deg; */
        -webkit-transform: translateY(-25px) rotate(90deg);
        -moz-transform: translateY(-25px) rotate(90deg);
        -o-transform: translateY(-25px) rotate(90deg);
        transform: translateY(-25px) rotate(90deg);
        height: 150px;
        
    }

    .learning-projects {
        width: 100%;
      }

    

}