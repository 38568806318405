.habilidades {
  padding: 5rem 0;
  z-index: 1;
}

.skills-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skills-container h1 {
  text-align: center;
  font-family: inter, sans-serif;
}



  .tag-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  
  #stage {


    margin: 200px auto 150px;
    width: 900px;
    height: 200px;
    -webkit-perspective: 20000;
    -moz-perspective: 800;
    perspective: 800; 
    display: flex;
  }
  
  #rotate-1 {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 575px;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(30deg);
    -moz-transform: rotateX(30deg);
    transform: rotateX(30deg);
  }

  #rotate-2 {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 575px;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(-7deg);
    -moz-transform: rotateX(-7deg);
    transform: rotateX(-7deg);

  }

  #rotate-3 {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;

    width: 575px;
    /* height: 575px; */
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(-40deg);
    -moz-transform: rotateX(-40deg);
    transform: rotateX(-40deg);
  }
  
  .ring {
    margin: 0 auto;
   /*  height: 110px;
    width: 500px; */
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  @keyframes y-color1 {
    0%{
      opacity: 0.325;
    }
    25%{
      opacity: 0.1;
    }
    75%{
      opacity: 1;
    }
    100%{
      opacity: 0.325;
    }  
  }
 
  @keyframes y-color2 {
    0%{
      opacity: 0.145;
    }  
    5%{
      opacity: 0.1; 

    }
    55% {
      opacity: 1;       
    }
    
    100%{
      opacity: 0.145;
    } 
  }

  @keyframes y-color3 {
    0%{
      opacity: 0.585;
    }
    35% {
      opacity: 1;
    }
    85%{
      opacity: 0.1;
    }
    100%{
      opacity: 0.585;
    }
  }

  @keyframes y-color4 {
    0%{
      opacity: 0.765;
    }
    15% {
      opacity: 1;
    }
    55%{
      opacity: 0.1;
    }
    100%{
      opacity: 0.765;
    }
    
  }

  @keyframes y-color5 {
    0%{
      opacity: 0.855;
    }
    45%{
      opacity: 0.1;
    }
    95%{
      opacity: 1;
    }
    100%{
      opacity: 0.855;
    }
  }

  .poster {
    position: absolute;
    left: 244px;
    width: 75px;
    -webkit-animation-duration: 15s;
    -moz-animation-duration: 15s;
    animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    animation-timing-function: linear;    
  }

  img{
    width: 75px;
    
  }

  .poster:nth-child(1){
    -webkit-transform: rotateY(72deg) translateZ(350px);
    -moz-transform: rotateY(72deg) translateZ(350px);
    transform: rotateY(72deg) translateZ(350px);
    -webkit-animation-name: y-color1;
    -moz-animation-name: y-color1;
    animation-name: y-color1;
  }
  
  .poster:nth-child(2){
    -webkit-transform: rotateY(144deg) translateZ(350px);
    -moz-transform: rotateY(144deg) translateZ(350px);
    transform: rotateY(144deg) translateZ(350px);
    -webkit-animation-name: y-color2;
    -moz-animation-name: y-color2;
    animation-name: y-color2;

  }

  .poster:nth-child(3){
    -webkit-transform: rotateY(216deg) translateZ(350px);
    -moz-transform: rotateY(216deg) translateZ(350px);
    transform: rotateY(216deg) translateZ(350px);
    -webkit-animation-name: y-color3;
    -moz-animation-name: y-color3;
    animation-name: y-color3;
  }

  .poster:nth-child(4){
    -webkit-transform: rotateY(288deg) translateZ(350px);
    -moz-transform: rotateY(288deg) translateZ(350px);
    transform: rotateY(288deg) translateZ(350px);
    -webkit-animation-name: y-color4;
    -moz-animation-name: y-color4;
    animation-name: y-color4;
  }
  .poster:nth-child(5){
    -webkit-transform: rotateY(360deg) translateZ(350px);
    -moz-transform: rotateY(360deg) translateZ(350px);
    transform: rotateY(360deg) translateZ(350px);
    -webkit-animation-name: y-color5;
    -moz-animation-name: y-color5;
    animation-name: y-color5;
  }
  
  #ring-1, #ring-2, #ring-3 {    
    -webkit-animation-name: y-spin;
    -moz-animation-name: y-spin;
    animation-name: y-spin;
    -webkit-animation-duration: 15s;
    -moz-animation-duration: 15s;
    animation-duration: 15s;
  }
  
  
  
  @keyframes y-spin {
    0% {
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      transform: rotateY(0deg);      
    }
    100% {
      -webkit-transform: rotateY(360deg);
      -moz-transform: rotateY(360deg);
      transform: rotateY(360deg);      
    }
  }


































  @media only screen and (max-width: 767px) {

  
    
 
    
    
      .tag-logo{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 150px;
      }
      
      #stage {
        margin: 1px auto;
        width: 330px;
      }
      
      #rotate-1 {
        width: 330px;

        
        
      }
    
      #rotate-2 {
        width: 330px;
        
      }
    
      #rotate-3 {
        
    
        width: 330px;

      }
      
      .poster {
        position: absolute;
        left: 145px;
        width: 40px;
      }
    

    
      img{
        width: 40px;
        
      }

      .poster:nth-child(1){
        
        -webkit-transform: rotateY(72deg) translateZ(150px);
        -moz-transform: rotateY(72deg) translateZ(150px);
        transform: rotateY(72deg) translateZ(150px);    
      }
      
      .poster:nth-child(2){
        -webkit-transform: rotateY(144deg) translateZ(150px);
        -moz-transform: rotateY(144deg) translateZ(150px);
        transform: rotateY(144deg) translateZ(150px);    
      }
    
      .poster:nth-child(3){
        -webkit-transform: rotateY(216deg) translateZ(150px);
        -moz-transform: rotateY(216deg) translateZ(150px);
        transform: rotateY(216deg) translateZ(150px);    
      }
    
      .poster:nth-child(4){
        -webkit-transform: rotateY(288deg) translateZ(150px);
        -moz-transform: rotateY(288deg) translateZ(150px);
        transform: rotateY(288deg) translateZ(150px);
      }
      .poster:nth-child(5){
        -webkit-transform: rotateY(360deg) translateZ(150px);
        -moz-transform: rotateY(360deg) translateZ(150px);
        transform: rotateY(360deg) translateZ(150px);
    
      }
  



  }
  
