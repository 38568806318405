.modal{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: none;
    align-items: center;
    justify-content: center;
  
  }
  
  .modal.is-open{
    display: grid;
  }
 