.navigation-bar {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 0;
  z-index: 999;
}

.container-fluid {
  max-width: 700px;
}

.navbar-brand {
  font-family: inter;
}

.link-animation {
  text-decoration: none;
  background-size: 0 2px, 0 2px !important;
  background-position: 100% 100%, 0 100% !important;
  background-repeat: no-repeat !important;
  transition: background-size 400ms;
}

.link-animation:hover {
  background-size: 0 2px, 100% 2px !important;
}



.navbar-toggler {
  width: 30px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #bbe1fa;
  border-radius: 50%;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* navigation clicked */

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

/* state when navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  margin-top: -20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=1);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 20px;
  transform: rotate(0deg);
}
